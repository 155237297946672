.back {
   
   
    /* background-image: url('../../../assets/images/home/homeEllipse.png'); */
    justify-content: center;
    align-items: center;
}

.custom-style {
    padding-top: 40px;
}
.hero-head {
    font-weight: 600;
    font-size: 27px;
    line-height: 37.5px;
}

#hero-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.back--img {
    background-image: url('/public/assets/woman.png');
    background-size: 'cover';
    height: 'auto';
    object-fit: 'cover';
    background-repeat: no-repeat;
    background: '#EBEEF5' !important;
 
}

/* Apply different styles for medium screens and larger using a media query */
@media (min-width: 768px) {
    .back {
        height: 620px;
    }
    .custom-style {
        padding-top: 100px;
    }
    .hero-head {
        font-weight: 550;
        font-size: 30px;
        line-height: 50px;
    }
    #hero-desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 30.6px;
    }
}

/* @media screen and (max-width: '425px') {
    .back--img{
        height: 350px !important;
    }
} */
