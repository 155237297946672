.testimonials{
    overflow-x: hidden;
    &--title{
        font-weight: 500;
        font-size: 40px;
        // margin-top: 1.5rem;
    }
    &--text{
        font-size: 18px;
        color: $dark;
    }
    &__slide{
        margin-top: -4rem !important;
        transform: scale(2.5) !important;
        background-color: gray !important;
        overflow-x: hidden
    }
    &__card{
        overflow-x: hidden;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
    }
}
.testimonies{
    /* Carousel slide with background image */
.slide-container {
    position: relative;
    height: 300px; /* Adjust based on your image size */
    // color: white; /* Text color */
    // background-image: url('../../public/assets/testimony.png');
    background-repeat: no-repeat;
    border-radius: 20px;
  }
  
  /* Glassmorphism text overlay */
  .text-overlay {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%; /* Adjust based on your preference */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    text-align: center;
  }
  
  /* Custom arrow button styling */
.slick-prev, .slick-next {
    z-index: 1; /* Ensure arrows are above all other content */
    top: 50%; /* Center vertically */
    width: 40px; /* Width of the arrow buttons */
    height: 40px; /* Height of the arrow buttons */
    background-color: rgba(0,0,0,0.5); /* Semi-transparent black background */
    border-radius: 20px; /* Circular buttons */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slick-prev { left: 10px; } /* Position the previous button */
  .slick-next { right: 10px; } /* Position the next button */
  
  .slick-prev:before, .slick-next:before {
    font-size: 20px;
    line-height: 1;
    color: white; /* Color of the arrow icons */
    opacity: 1; /* Fully opaque */
  }
  
  /* Adjust positioning if necessary based on your layout */
  .slick-prev {
    left: 25px; /* Adjust this value to move the left arrow button */
  }
  
  .slick-next {
    right: 25px; /* Adjust this value to move the right arrow button */
  }

  .slick-dots li button::before{
    width: 10px;
  }
  
}