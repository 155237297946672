#learnHead {
    font-size: 40px;
    line-height: 50px;
}

@media only screen and (max-width: 600px) {
    #learnHead {
        font-size: 25px;
        line-height: 32px;
    }
}
