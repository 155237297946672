.formik-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}

.conta {
    padding-left: 0rem;
    padding-right: 0rem;
}

/* Apply different styles for medium screens and larger using a media query */
@media (min-width: 768px) {
    .formik-label {
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
    }
    .conta {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}
