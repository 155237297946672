.sidebar{
    height: 100%;
    &--link{
        padding: 1rem;
        color:  $primary;
        margin: .2rem;
        &:hover{
            background-color: #F2F5FA;
        }
        &.active{
            border-left: 4px solid $primary;
            color:  $primary;
            background-color: $semi-primary;
            border-radius: 4px;
        }
    }
}