.signup {
    &__col {
        input[type='checkbox'] {
            accent-color: $primary;
            transform: scale(1.2);
        }
        .custom-select {
            appearance: none; /* Removes default styling of select */
            background-color: white;
            border: 1px solid #ccc;
            padding: 10px;
            width: 100%;
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M6%2010L0.803847%200.5L11.1962%200.5L6%2010Z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E');
            background-repeat: no-repeat;
            background-position: right 10px center; /* Position the icon on the right */
            background-size: 12px 12px; /* Size of the icon */
            font-size: 14px !important;
          }
        &--title {
            font-size: 35px;
            color: $dark;
            font-weight: 600;
            letter-spacing: -1px;
        }
        &--text {
            color: #60666d;
            font-size: 16px;
        }
        &--label{
            font-size: 14px !important;
            margin-bottom: 8px;
        }
        &--inp {
            border: 1px solid #888c91 !important;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
            padding: 0.8rem !important ;
            height: 48px;
            font-size: 14px;
            &::placeholder{
                font-size: 14px;
                color: #B0B3B6 !important;
            }
        }
        &--inpisIvalid {
            outline-color: 0 none !important;
            border: 1px solid red !important;
            padding: 0.8rem !important ;
            height: 48px;
            font-size: 14px;
            &::placeholder{
                font-size: 14px;
                color: #B0B3B6;
            }
        }
       
        &--icon {
            position: absolute;
            top: 2.7rem;
            right: 25px;
            cursor: pointer;
        }
        &--link {
            color: $primary !important;
            text-decoration: none;
            font-size: 14px;
            font-weight: 600;
        }
        &--btn {
            width: 100%;
            padding: 10px;
            color: $white;
            border-radius: 6px;
            background-color: $primary;
            border: 2px solid $primary;
            height: 48px;
            font-weight: 600;
            font-size: 14px;
            transition: all ease-in-out 0.2s;
            &:hover {
                background-color: transparent;
                border: 2px solid $primary;
                color: $primary;
            }
        }
        &--disabled {
            width: 100%;
            padding: 10px;
            background-color: $primary;
            padding: 10px;
            border-radius: 7px;
            color: $white;
            cursor: none;
            font-weight: 500;
            font-size: 14px;
            border: 2px solid $primary;
        }
    }
}
@media screen and (max-width: 768px) {
    .signup {
        &__col {
            &--logo {
                margin-top: 0 !important;
                margin-left: 25%;
            }
        }
    }
    .signin {
        &__col {
            &--logo {
                margin-top: 0;
            }
            &--link {
                text-align: center;
                justify-content: center;
                align-items: center;
                margin-left: 25%;
            }
        }
    }
}
