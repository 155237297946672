.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 14px 24px;
    background-color: rgba(255, 255, 255, 0.8); /* Glass effect by default */
    color: #2C4A8B;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-decoration: none;
    border: 1px solid #80D7F5;
    backdrop-filter: blur(10px); /* Glassmorphism effect */
    transition: background-color 0.5s ease, color 0.5s ease;
    transition: all .5s;
    animation: glowing 2s infinite alternate;
    font-weight: 500;
    font-size: 16px;
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 5px #80D7F5, 0 0 10px #80D7F5, 0 0 15px #80D7F5;
        border-color: #80D7F5;
    }
    100% {
        box-shadow: 0 0 7px #2C4A8B, 0 0 30px #ffff, 0 0 40px #80D7F5;
        border-color:  #80D7F5;
    }
}
// .floating-button:hover {
//     background-color: #0056b3; /* Darkens the button on hover */
// }