.navigation {
    background-color: #fff;
    // box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
    z-index: 77;
    &--logo {
        width: 155px;
    }
    &__menu {
        &--item {
            font-weight: 600;
            color: #1d1d1d;
            font-size: 18px;
            width: 100%;
            transition: all 0.5s ease-out;
            position: relative;
        }
        &--link {
            color: #1d1d1d;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            bottom: 0;
            width: 100%;
            transition: all 0.3s ease-out;
            -moz-transition: 0.6s ease-in-out;
            -o-transition: 0.6s ease-in-out;
            position: relative;
            margin-left: 1rem;
            &:hover {
                border-bottom: 3px solid #2c4a8b;
            }
        }
        &--active {
            color: #2c4a8b;
            font-weight: 500;
            font-size: 16px;
            text-decoration: none;
            transform: scale(1.1);
            &:hover {
                border-bottom: 3px solid #2c4a8b;
            }
        }

        &--auth {
            background: transparent;
            font-weight: 500;
            color: #1d1d1d;
            border: 1px solid transparent;
            font-size: 16px;
            color: #2c4a8b;
            &:hover {
                color: #2c4a8b;
            }
        }
        &--btn {
            border: 1px solid #2c4a8b;
            padding: 0.4rem 1.3rem;
            font-weight: 500;
            border-radius: 7px;
            text-decoration: none;
            color: #2c4a8b;
            background: transparent;
            font-size: 16px;
            transition: all 0.5s ease-in-out;
            text-align: center;
            &:hover {
                background-color: #2c4a8b;
                color: #fff;
            }
        }
    }
    &__mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        .menu-line {
            width: 18px;
            height: 2px;
            background-color: #333;
            margin: 3px 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.35s ease-in-out;
            -moz-transition: 0.35s ease-in-out;
            -o-transition: 0.35s ease-in-out;
            transition:  all 2.35s ease-in-out;
        }
        .close-icon {
            font-size: 24px;
            cursor: pointer;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.35s ease-in-out;
            -moz-transition: 0.35s ease-in-out;
            -o-transition: 0.35s ease-in-out;
            transition: 0.35s ease-in-out;
          }
        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 999;
            transition: background-color 0.3s ease-in-out;
            backdrop-filter: blur(2px);
          }
       
    }
    &__sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
        padding: 20px;
        z-index: 1000;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 1.35s ease-in-out;
        -moz-transition: 0.35s ease-in-out;
        -o-transition: 0.35s ease-in-out;
        transition: 0.35s ease-in-out;
        &-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &--link{
            border-bottom: 1px solid #CFD1D3;
            padding-top: 20px;
            padding-bottom: 20px;
            font-weight: 500;
            color: #111A24;
            font-size: 16px;
        }
        &--btn{
            width: 100%;
            height: 50px;
            border-radius: 7px;
            border: 1px solid #2C4A8B;
            background-color: #2C4A8B;
            margin-top: 50px;
            font-weight: 500;
            font-size: 16px;
            color: #fff;
        }
        &--auth{
            width: 100%;
            height: 50px;
            border-radius: 7px;
            border: 1px solid #2C4A8B;
            background-color: transparent;
            margin-top: 20px;
            font-weight: 500;
            font-size: 16px;
            color: #2C4A8B;
        }
    }
}
//************************** ANIMATED NAV COLLAPSE ICON **************************//
.animated-icon1,
.animated-icon2,
.animated-icon3 {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.6s ease-in-out;
    -moz-transition: 0.6s ease-in-out;
    -o-transition: 0.6s ease-in-out;
    transition: 0.6s ease-in-out;
    cursor: pointer;
}

.animated-icon1 span,
.animated-icon2 span,
.animated-icon3 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.35s ease-in-out;
    -moz-transition: 0.35s ease-in-out;
    -o-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
}

.animated-icon1 span {
    background: $primary;
}

.animated-icon2 span {
    background: #e3f2fd;
}

.animated-icon3 span {
    background: #f3e5f5;
}

.animated-icon1 span:nth-child(1) {
    top: 0px;
}

.animated-icon1 span:nth-child(2) {
    top: 10px;
}

.animated-icon1 span:nth-child(3) {
    top: 20px;
}

.animated-icon1.open span:nth-child(1) {
    top: 11px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.animated-icon1.open span:nth-child(3) {
    top: 11px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
@media screen and (max-width: 500px) {
    .navigation {
        &--logo {
            width: 110px;
        }
        &--avatar {
            margin-left: 2rem;
            background-color: yellowgreen;
        }
    }
}
@media screen and (max-width: 767.98px) {
    .navigation {
        .navbar-collapse {
            height: 100vh;
        }

        &__menu {
            margin-top: 2rem;

            &--item {
                border-bottom: 1px solid #cfd1d3;
                padding: 2rem;
            }
            &--link {
                margin-left: 0;
            }
            &--auth {
                padding: 0.8rem 2rem;
                border: 1px solid $primary;
                border-radius: 7px;
                margin-bottom: 0.8rem;
                color: #2c4a8b;
                text-align: center;
            }
            &--btn {
                margin-top: 4rem;
                border-radius: 7px;
                padding: 0.8rem 2rem;
                background-color: #2c4a8b;
                color: #fff;
                margin-bottom: 1rem;
            }
        }
    }
}
