.summary {
    .btn-close {
        color: #0000 !important;
    }
    hr {
        background-color: #f7f9fa;
        opacity: 0.1;
    }
    input[type='radio'] {
        accent-color: $primary;
        transform: scale(1.2);
    }
    input[type='checkbox'] {
        accent-color: $primary;
        transform: scale(1.2);
    }
    &--title {
        font-weight: 500;
        font-size: 21px;
        color: #394049;
        line-height: 30.26px;
    }
    &--exit {
        // height: 48px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #e8ebed;
        padding: 14px 15px;
        font-size: 14px;
        font-weight: 500;
        color: #394049;
    }
    &--badge {
        padding: 4px 15px;
        color: $primary;
        border: 1px solid $primary;
        font-weight: 500;
        font-size: 16px;
        height: 34px;
    }
    &--text {
        font-size: 14px;
        font-weight: 500;
    }
    &__card {
        background-color: #f7f9fa !important;
        border: 1px solid #e8ebed !important;
        border-radius: 7px !important;
        // width: 657px;
        &--amount {
            font-size: 35px;
            font-weight: 700;
            letter-spacing: -1.2px;
        }
        &--subtitle {
            font-size: 18px;
            font-weight: 600;
            line-height: 24.2px;
            color: #111a24;
        }
        &--text {
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
        }
        &--text2 {
            color: #394049;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
        }
        &--text3 {
            font-size: 14px;
            font-weight: 500;
            color: #111a24;
        }
        &--label {
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            color: #394049;
            margin-bottom: 8px;
        }
        &--input {
            height: 50px;
            border: 1px solid #95a4c5;
            font-weight: 500 !important;
            font-size: 16px;
            &::placeholder {
                color: #b0b3b6 !important;
                transition: color 0.5s ease;
                font-weight: 400 !important;
            }
            &:focus::placeholder {
                color: transparent;
            }
        }
        &--apply {
            background-color: $primary;
            padding: 16px 33px;
            border-radius: 7px;
            border: 2px solid $primary;
            color: #fff;
            height: 50px;
            font-weight: 500;
        }
        &--btn {
            padding: 15px 30px;
            color: #fff;
            border-radius: 7px;
            background-color: $primary;
            border: 1px solid $primary;
            height: 50px;
            font-weight: 500;
            font-size: 14px;
        }
        &--btnd {
            padding: 15px 30px;
            color: #fff;
            border-radius: 7px;
            background-color: $primary;
            border: 1px solid #ccc;
            height: 50px;
            font-weight: 500;
            font-size: 14px;
        }
        &--payment {
            background-color: transparent;
            outline: none;
            border: 1px solid #d1d9e0;
            border-radius: 8px;
            &-options {
                border-bottom: 1px solid #e8ebed;
            }
        }
        &--smalltext {
            font-size: 14px;
            font-weight: 500;
        }
    }
    &__addon {
        &--username {
            font-size: 16px;
            color: #394049;
            font-weight: 500;
        }
        &--card {
            width: 90%;
        }
        &--unselectedcard {
            font-size: 14px;
            font-weight: 500;
        }
        &--selectedcard {
            background-color: #f2fafd !important;
            border: 1px solid #67cff3 !important;
        }
        &--subtitle {
            font-size: 30px;
            font-weight: 600;
            letter-spacing: -1.3px;
        }
        &--text {
            font-size: 16px;
            color: #888c91;
        }
        &--name {
            font-size: 18px;
            font-weight: 600;
        }
        &--description {
            font-size: 14px;
            color: #888c91;
            font-weight: 400;
            line-height: 21px;
        }
        &--radio {
            input[type='radio'] {
                accent-color: $primary;
                transform: scale(1.2);
            }
        }
        &--amount {
            font-size: 16px;
            color: #111a24;
            font-weight: 500;
            line-height: 24px;
        }
    }
}

@media screen and (max-width: 1025px) {
    .summary {
        &__addon {
            &--card {
                width: 90%;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .summary {
        &__addon {
            &--col {
                // background-color: blue;
                margin: 0.5rem;
                width: 45% !important;
            }
            &--card {
                width: 100%;
                // padding: 1rem;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .summary {
        &__addon {
            &--col {
                width: 45% !important;
            }
            &--card {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 854px) {
    .summary {
        &__addon {
            &--col {
                // background-color: blue;
                // margin: .5rem;
                width: 40% !important;
            }
            &--card {
                width: 100%;
                // padding: 1rem;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .summary {
        &__addon {
            &--col {
                
                width: 40% !important;
            }
            &--card {
                width: 110%;
                // padding: 1rem;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .summary {
        &__addon {
            &--rowsm {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust minmax for your preferred card width */
                gap: 16px; /* Space between cards */
                padding: 8px; /* Padding around the grid */
            }
            &--card {
                border: 1px solid #ccc;
                border-radius: 8px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }
           
        }
    }
}

@media screen and (max-width: 565px) {
    .summary {
        &__addon {
            &--rowsm {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust minmax for your preferred card width */
                gap: 16px; /* Space between cards */
                padding: 8px; /* Padding around the grid */
            }
            &--card {
                border: 1px solid #ccc;
                border-radius: 8px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }
           
        }
    }
}
@media screen and (max-width: 380px) {
    .summary {
        &__addon {
            &--rowsm {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(135px, 1fr)); /* Adjust minmax for your preferred card width */
                gap: 10px; /* Space between cards */
                padding: 8px; /* Padding around the grid */
            }
            &--card {
                border: 1px solid #ccc;
                border-radius: 8px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }
           
        }
    }
}
