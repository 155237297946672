.contact{
    &--btn{
        padding: 14px;
        border: 2px solid ;
        background-color: #2C4A8B;
        border-radius: 10px;
        width: 100%;
        color: #fff;
        font-weight: 600;
        transition: all ease-in-out .4s;
        &:hover{
            color: #2C4A8B;
           background-color: transparent;
        }
    }
    &--title{
        font-weight: 600;
        font-size: 40px;
    }
    &--text{
        font-size: 18px;
        color: #111A24;
    }
}