#planCardHead {
    font-size: 25px;
    line-height: 30.26px;
}

#planCardDesc {
    font-size: 16px;
    line-height: 24px;
}
#priceText {
    font-size: 35px;
    line-height: 42.36px;
}

#perYear {
    font-size: 20px;
    line-height: 24.2px;
}

#featureHead {
    font-size: 16px;
    line-height: 24px;
}
#discountText {
    font-size: 20px;
    line-height: 28px;
}

@media only screen and (max-width: 600px) {
    #planCardHead {
        font-size: 25px;
        line-height: 32px;
    }
    #planCardDesc {
        font-size: 14px;
        line-height: 24px;
    }
    #priceText {
        font-size: 25px;
        line-height: 32px;
    }

    #perYear {
        font-size: 14px;
        line-height: 24px;
    }
    #featureHead {
        font-size: 14px;
        line-height: 24px;
    }
}
