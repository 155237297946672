.contain {
    /* height: 250px; */
    /* background-size: contain; */
    /* background-repeat: no-repeat; */
    position: relative;
    /* background-image: url('../../../assets/images/subHeader/Ellipse.png'); */
    /* display: flex; */
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    margin-top: 2rem;
}

#inner {
    padding-top: 40px;
}

#sub-header-head {
    font-weight: 600;
    font-size: 25px;
    letter-spacing: -0.32px;
}
#sub-header-desc {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 768px) {
    #inner {
        padding-top: 100px;
        max-width: 70%;
    }
    #sub-header-head {
        font-weight: 600;
        font-size: 40px;
    }

    #sub-header-desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 30.6px;
    }
}
