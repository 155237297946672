.get-started {
    width: 100%;
}
#btn-div {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
#started-head {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
}
#started-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

/* medium screen size and higher */
@media (min-width: 768px) {
    .get-started {
        width: 60%;
    }
    #btn-div {
        width: 35%;
    }
    #started-head {
        font-size: 40px;
        line-height: 50px;
        font-weight: 500;
    }
    #started-desc {
        font-size: 18px;
        font-weight: 500;
        line-height: 30.6px;
    }
}
