@import './_variables'
@import './_mixins'
@import './producthero'
@import './pricingHero'
@import './plans'
@import './frequentlyAskedQuestions'
@import './navbar'
@import './home'
@import './downloads'
@import './signup'
@import './verification'
@import './contact'
@import './testimonials'
@import './footer'
@import './sidebar'
@import './plansTables'
@import './summary'
@import './addMoney'
@import './floatBtn'

*
  box-sizing: border-box
  outline: none

html,
body
  height: 100%
 

body 
  position: relative
  margin: 0
  font-family: 'Inter', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $main-text-color
  background-color: $main-background-color
  overflow-x: hidden
  width: 100%
 

code 
  font-family: 'Inter', sans-serif

