.pricehero {
    overflow-x: hidden;
    &--text {
        color: #394049;
        font-size: 18px;
    }
    &--boldertext {
        font-size: 14px;
        color: $primary;
        font-weight: 600;
        // padding-top: 1rem !important;
    }
    &--title {
        font-size: 40px;
        font-weight: 600;
        color: #111a24;
        line-height: 50px;
        letter-spacing: -1.5px;
    }
}
@media screen and (max-width: 768px) {
    .pricehero {
        &--title {
            font-size: 25px;
            line-height: 35px;
            // font-weight: 600;
        }
        &__main {
            height: 490px;

            &--text {
                width: 100%;
                height: 100px;
                transform: translate(0%, 50%);
            }
            &--bottom {
                width: 100%;
            }
        }
    }
}
