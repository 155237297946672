.selectdiv {
    position: relative;
    /*Don't really need this just for demo styling*/

    // float: left;
    min-width: 185px;
    margin: 0 25%;
}

.selectdiv:after {
    color: $white;
    right: 11px;
    top: 6px;
    height: 48px;
    padding: 15px 0px 0px 8px;
    border-left: 0.5px solid $white;
    position: absolute;
    pointer-events: none;
}

/* IE11 hide native button (thanks Matt!) */
button::-ms-expand {
    display: none;
}

.selectdiv button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add some styling */

    display: block;
    width: 100%;
    height: 48px;
    margin: 5px 0px;
    padding: 0px 24px;
    font-size: 16px;
    line-height: 1.75;
    color: $white;
    background-color: $primary !important;
    background-image: none;
    border: 1px solid $primary;
    -ms-word-break: normal;
    word-break: normal;
    border-radius: 10px;
    font-weight: 500;
}
.downloads {
    margin-top: 104px;
    .form-select {
        background-color: transparent !important;
        border: 0.5px solid #95a4c5 !important;
        color: $primary;
    }

    &__wrapper {
        position: relative;
        display: inline-block;

        &--select {
            color: #2c4a8b !important;
            padding: 12px 24px 12px 30px !important;
            border: 0.5px solid #95a4c5 !important;
            border-radius: 7px;
            height: 51px !important;
            font-weight: 500 !important;
            width: 375px !important;
        }
        &--toggle::after {
            display: none !important;
            color: #2c4a8b !important;
            border: 1px solid gray !important;
        }
    }
    &--title {
        font-size: 40px;
        font-weight: 500;
        line-height: 50px;
        letter-spacing: -1.6px;
        margin-bottom: 12px;
    }
    &--text {
        font-size: 18px;
        line-height: 30.6px;
        font-weight: 400;
        letter-spacing: -0.36px;
        margin-bottom: 40px;
    }
    &--activeNav {
        border-bottom: 2px solid $primary;
        margin: 0;
        text-align: start;
        font-weight: 500;
    }
    &--item {
        color: #2c4a8b !important;
        padding: 8px !important;
        &:hover {
            background-color: #f2f5fa;
        }
    }
    &--link {
        padding: 14px 26px;
        border: none;
        color: #60666d !important;
        // margin-bottom: 1rem;
        font-size: 18px;
        font-weight: 500 !important;

        text-align: center;
        height: 58.2px;

        .fa-brands {
            font-size: 25px;
        }
    }
    &--active {
        background-color: transparent;
        padding: 14px 26px;
        color: $primary !important;
    }

    &__table {
        width: 100% !important;
        &--title {
            font-size: 40px;
            font-weight: 500;
            letter-spacing: -1.4px;
        }
        &--th1 {
            font-size: 14px;
            font-weight: 500;
            color: $dark-200 !important;
            line-height: 21px;
            padding-bottom: 20px !important;
            width: 25% !important;
            position: relative;
            left: 2.3%;
            // background-color: #2c4a8b !important;
        }
        &--th2 {
            font-size: 14px;
            font-weight: 500;
            color: $dark-200 !important;
            line-height: 21px;
            padding-bottom: 20px !important;
            width: 10% !important;
            position: relative;
            // left: 5% !important;
        }
        &--th3 {
            font-size: 14px;
            font-weight: 500;
            color: $dark-200 !important;
            line-height: 21px;
            padding-bottom: 20px !important;
            width: 15% !important;
            position: relative;
            left: 5%;
        }
        &--td {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }
        &--version {
            font-size: 16px;
            font-weight: 500;
            color: #1d1d1d !important;
            margin-bottom: 0.5rem;
        }
        &--description {
            font-size: 14px;
            color: #60666d;
            line-height: 21px;
        }
        &--text {
            font-size: 16px;
            font-weight: 400;
            line-height: 0px;
        }
        &--badge {
            background-color: #f2f5fa;
            padding: 11px 12px;
            color: $primary;
            width: 113px;
            text-align: center;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 500;
            margin-left: 16px;
        }
    }
    &__switch {
        background-color: #f1f2f4;
        width: 31.8% !important;
        border-radius: 11px;
        padding: 5px 6px;
        margin-bottom: 24px;
        justify-content: center;
        height: 55px;
        &--btn {
            color: #888c91;
            font-weight: 500;
            font-size: 16px;
            background-color: transparent;
            border: none;
            height: 45px;
            padding: 0px 15.2px;
        }
        &--activeBtn {
            font-weight: 500;
            font-size: 16px;
            color: $primary;
            background-color: #ffffff !important;
            border: none;
            padding: 0px 15.2px;
            border-radius: 8px;
            height: 45px;
        }
        &--badge {
            color: #18ba5c !important;
            background-color: #d4ecde;
            left: 20px !important;
            background-color: #e8fbf0 !important;
            border-radius: 100px !important;
            font-size: 14px !important;
            font-weight: 500;
            border: 1px solid #d4ecde !important;
            letter-spacing: -0.14px;
        }
    }
}
@media screen and (width <= 786px) {
    .downloads {
        margin-top: 0;
        &__switch {
            &--btn {
                font-size: 12px;
            }
            &--activeBtn {
                font-size: 12px;
                background-color: $primary !important;
                color: #ffffff !important;
            }
        }
    }
}
@media (width >= 1441px) {
    .downloads__switch {
        width: 35.9% !important;
    }
}
@media (width >= 1024px) {
    .downloads__switch {
        width: 35.8% !important;
    }
}

// @media screen and (min-width: '1024px') {
// .downloads {
//     .form-select {
//         background-color: transparent !important;
//         border: 0.5px solid #95a4c5 !important;
//         color: $primary;
//     }
//     .selectdiv {
//         position: relative;
//         /*Don't really need this just for demo styling*/

//         // float: left;
//         min-width: 185px;
//         margin: 0 25%;
//     }

//     .selectdiv:after {
//         content: '\f078';
//         font: normal normal normal 16px/1 FontAwesome;
//         color: $white;
//         right: 11px;
//         top: 6px;
//         height: 48px;
//         padding: 15px 0px 0px 8px;
//         border-left: 0.5px solid $white;
//         position: absolute;
//         pointer-events: none;
//     }

//     /* IE11 hide native button (thanks Matt!) */
//     button::-ms-expand {
//         display: none;
//     }

//     .selectdiv button {
//         -webkit-appearance: none;
//         -moz-appearance: none;
//         appearance: none;
//         /* Add some styling */

//         display: block;
//         width: 100%;
//         height: 48px;
//         margin: 5px 0px;
//         padding: 0px 24px;
//         font-size: 16px;
//         line-height: 1.75;
//         color: $white;
//         background-color: $primary;
//         background-image: none;
//         border: 1px solid $primary;
//         -ms-word-break: normal;
//         word-break: normal;
//         border-radius: 10px;
//         font-weight: 500;
//     }
//     &__wrapper {
//         position: relative;
//         display: inline-block;

//         &--select {
//             color: #2c4a8b !important;
//             padding: 7px 18px !important;
//             border: 0.5px solid #95a4c5 !important;
//             border-radius: 7px;
//             height: 48px;
//             font-weight: 500 !important;
//             width: 300px !important;
//         }
//         &--toggle::after {
//             display: none !important;
//             color: #2c4a8b !important;
//             border: 1px solid gray !important;
//         }
//     }
//     &--title {
//         font-size: 40px;
//         font-weight: 500;
//         line-height: 50px;
//         letter-spacing: -1.4px;
//     }
//     &--text {
//         font-size: 18px;
//         line-height: 30.6px;
//         font-weight: 400;
//     }
//     &--activeNav {
//         border-bottom: 2px solid $primary;
//         margin: 0;
//         text-align: start;
//         font-weight: 500;
//     }
//     &--item {
//         color: #2c4a8b !important;
//         padding: 8px !important;
//         &:hover {
//             background-color: #f2f5fa;
//         }
//     }

//     &--link {
//         padding: 14px 26px;
//         border: none;
//         color: #60666d !important;
//         // margin-bottom: 1rem;
//         font-size: 18px;
//         font-weight: 500 !important;

//         text-align: center;
//         height: 58.2px;

//         .fa-brands {
//             font-size: 25px;
//         }
//     }
//     &--active {
//         background-color: transparent;
//         padding: 14px 26px;
//         color: $primary !important;
//     }
//     &__tab{
//         display:none !important;
//     }
//     &__table {
//         width: 88% !important;
//         &--title {
//             font-size: 40px;
//             font-weight: 500;
//             letter-spacing: -1.4px;
//         }
//         &--th1 {
//             font-size: 14px;
//             font-weight: 500;
//             color: $dark-200 !important;
//             line-height: 21px;
//             padding-bottom: 20px !important;
//             width: 32% !important;
//             position: relative;
//             left: 2.3%;
//         }
//         &--th2 {
//             font-size: 14px;
//             font-weight: 500;
//             color: $dark-200 !important;
//             line-height: 21px;
//             padding-bottom: 20px !important;
//             width: 15% !important;
//             position: relative;
//             left: 5%;
//         }
//         &--th3 {
//             font-size: 14px;
//             font-weight: 500;
//             color: $dark-200 !important;
//             line-height: 21px;
//             padding-bottom: 20px !important;
//             width: 15% !important;
//             position: relative;
//             left: 5%;
//         }
//         &--td {
//             padding-top: 0px !important;
//             padding-bottom: 0px !important;
//         }
//         &--version {
//             font-size: 18px;
//             font-weight: 500;
//             color: #1d1d1d !important;
//             margin-bottom: 0.5rem;
//         }
//         &--description {
//             font-size: 14px;
//             color: #60666d;
//             line-height: 21px;
//         }
//         &--text {
//             font-size: 16px;
//             font-weight: 400;
//             line-height: 0px;
//         }
//     }
// }
// }
// @media screen and (max-width: '992px') {
//     .downloads {
//         .selectdiv {
//             position: relative;
//             /*Don't really need this just for demo styling*/

//             // float: left;
//             min-width: 185px;
//             margin: 0 25%;
//         }

//         .selectdiv:after {
//             content: '\f078';
//             font: normal normal normal 16px/1 FontAwesome;
//             color: $white;
//             right: 11px;
//             top: 1px;
//             height: 48px;
//             padding: 15px 0px 0px 8px;
//             border-left: 0.5px solid $white;
//             position: absolute;
//             pointer-events: none;
//         }

//         /* IE11 hide native button (thanks Matt!) */
//         button::-ms-expand {
//             display: none;
//         }

//         .selectdiv button {
//             -webkit-appearance: none;
//             -moz-appearance: none;
//             appearance: none;
//             /* Add some styling */

//             display: block;
//             width: 100%;
//             height: 48px;
//             margin: 5px 0px;
//             padding: 0px 24px;
//             font-size: 16px;
//             line-height: 1.75;
//             color: $white;
//             background-color: $primary;
//             background-image: none;
//             border: 1px solid $primary;
//             -ms-word-break: normal;
//             word-break: normal;
//             border-radius: 10px;
//             font-weight: 500;
//         }
//         &__tab{
//             display: block;
//         }

//     }
// }

// @media screen and (max-width: '768px') {
//     .downloads {
//         &--title {
//             font-size: 25px;
//         }
//         &--text {
//             font-size: 16px;
//         }
//         &__tab{
//             display: none;
//         }
//         &__table {

//             width: 100%;
//             &--th1 {
//                 width: 60% !important;
//             }
//             &--th2 {
//                 width: 25% !important;
//             }
//             &--th3 {
//                 width: 30% !important;
//             }
//         }
//     }
// }
