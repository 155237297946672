.footer {
    overflow-x: hidden;
    top: 0;
    bottom: 0;
    background-color: $primary;
    color: $dark-300;
    &--why {
        width: 34%;
        // background-color: red;
    }

    &--product{
        width: 30%;
        // background-color: green
    }
    &--company{
        width: 14%;
        // background-color: yellow;
    }

    &--title {
        text-decoration: none;
        color: $white;
        font-weight: 500;
        font-size: 14px;
        line-height: 27px;
    }
    &--text {
        line-height: 24px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffffb3;
    }
    &--line {
        height: 0.5px;
        width: 100%;
        background-color: $white;
        margin: 0;
        padding: 0;
        opacity: .4;
    }
    &__mobile{
        &--name{
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
        }
        &--text{
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
}
