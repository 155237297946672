// Cold colors
$aqua: #7fdbff;
$blue: #0074d9;
$navy: #001f3f;
$teal: #39cccc;
$green: #2ecc40;
$olive: #3d9970;
$lime: #01ff70;

// Warm colors
$yellow: #ffdc00;
$orange: #ff851b;
$red: #ff4136;
$fuchsia: #f012be;
$purple: #b10dc9;
$maroon: #85144b;

// Grayscale
$white: #ffffff;
$silver: #dddddd;
$gray: #aaaaaa;
$black: #111111;

$primary: #2C4A8B;
$white: #FFFFFF;
$semi-primary: #F2F5FA;
$light-blue: #EBEEF5;

$dark: #111A24;
$gray: #394049;

$main-background-color: $white;
$main-text-color: $black;
$divider-color: rgba($black, .14);

$main-text-size: 14px;
$main-border-radius: 6px;

$small-space: 16px;
$medium-space: 32px;
$large-space: 64px;

$phone-size: 600px;
$tablet-size: 992px;
$desktop-size: 1200px;

$blue-100: #EBEEF3;
$blue-200: #EBEEF5;
$blue-300: #F2F5FA;
$blue-400: #FAFCFF;
$blue-500: #E1F5FD;
$blue-800: #D5DBE8;

$dark-100: #60666D;
$dark-200: #888C91;
$dark-300: #CFD1D3;

$success-100: #18BA5C;

$danger: #DB5555;

$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * .875);
$input-height: 2.375rem;
$input-height-sm: 1.9375rem;
$input-height-lg: 3rem;
$input-btn-focus-width: .2rem;
$custom-control-indicator-bg: #dee2e6;
$custom-control-indicator-disabled-bg: #e9ecef;
$custom-control-description-disabled-color: #868e96;
$switch-height: calc(#{$input-height} * .8) !default;