.addMoney {
    
    &--input {
        background: #f2f5f7 !important;
        padding: 18px 15px;
        border-radius: 7px;
        font-weight: 600;
        font-size: 14px;
        &::placeholder{
            color: #ccc !important;
            font-weight: 600;
        }
        &:focus {
            border: 1px solid #f2f5f7 !important;
        }
    }
    &--label{
        font-size: 12px;
        font-weight: 600;
    }
    &--title {
        font-weight: 600;
        font-size: 20px;
        color: $dark;
    }
    &--balance{
        color: #60666D;
        font-weight: 400;
    }
    &--amount{
        font-weight: 600;
        font-size: 18px;
    }
    &--addBtn {
        color: $white;
        background-color: $primary;
        padding: 14px 28px;
        border: 1px solid $primary;
        border-radius: 7px;
        font-weight: 500;
        font-size: 14px;
        width: 143px;
    }
    &--close {
        color: $primary;
        background-color: transparent;
        padding: 14px 28px;
        border: 1px solid transparent;
        border-radius: 7px;
        font-weight: 500;
        font-size: 14px;
    }
}
