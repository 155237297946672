#mobile-head {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -0.32px;
}
#playS {
    width: 150px;
    height: 45px;
}

@media (min-width: 768px) {
    #mobile-head {
        font-weight: 400;
        font-size: 18px;
        letter-spacing: -0.32px;
    }
    #playS {
        width: 245px;
        height: 50.62px;
    }
}
