.icon {
    width: 48px;
    height: 48px;
}

#colorHead {
    font-size: 25px;
    line-height: 42.5px;
}
#ColorDesc {
    font-size: 18px;
    line-height: 30.6px;
}

/* small screen */
@media only screen and (max-width: 600px) {
    .icon {
        width: 30px;
        height: 30px;
    }
    #colorHead {
        font-size: 20px;
        line-height: 24.2px;
    }
    #ColorDesc {
        font-size: 14px;
        line-height: 24px;
    }
}
