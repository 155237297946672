#bulletHeadStyle {
    font-size: 32px;
    line-height: 42.36px;
}

#bulletItself {
    height: 10px;
    width: 8.93px;
}

/* small screen */
@media only screen and (max-width: 600px) {
    #bulletHeadStyle {
        font-size: 20px;
        line-height: 24.2px;
    }
    #bulletItself {
        height: 8px;
        width: 8px;
    }
}
