.home {
    position: relative;
    overflow: hidden !important;
    &__col {
        background-color: #ebeef5;
        &--title {
            font-size: 40px;
            padding-bottom: 1rem;
            color: $dark;
            letter-spacing: -1.2px;
            font-weight: 500;
        }
        &--text {
            font-size: 16px;
            line-height: 27.2px;
            font-weight: 400;
        }
    }
    &__col2 {
        width: 50%;
        overflow: hidden;
    }
}
@media screen and (max-width: '768px') {
    .home {
        &__col {
            &--title {
                margin-top: 1rem;
                font-size: 25px;
                font-weight: 600;
                letter-spacing: -.5px;
                line-height: 35px;
            }
            &--text {
                font-size: 16px;
            }
        }
    }
}
