.faqs {
    overflow-x: hidden;  overflow-x: hidden;
    &--title {
        text-align: center;
        font-size: 40px;
        color: #111a24;
        font-weight: 600;
    }
    .accordion-item {
        color: #000;
        border-top: 1px solid #cfd1d3 !important;
        // border-radius: none !important;
    }

    .accordion-button:focus {
        box-shadow: none;
        border-color: #111a24;
    }

    .accordion-button {
        color: #111a24;
        background-color: transparent !important;
        outline: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: -1%;
        padding-top: 2rem;
        border: 0;
    }
    .accordion-button:focus {
        color: #111a24;
        border: none !important;
    }
    .accordion-button::after {
        content: '—' !important;
        background-image: none !important;
        font-size: 20px;
        margin-bottom: 10px;
        color: $primary !important;
    }

    .accordion-button.collapsed::after {
        content: '\2b' !important;
        background-image: none !important;
        font-size: 24px;
        margin-bottom: 10px;
        color: $primary;
    }
    .accordion-body {
        border-radius: 0 0 10px 10px;
    }
}
