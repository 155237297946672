#footer-logo {
    height: 23.96px;
    width: 20.34px;
}
#footer-klak {
    font-size: 24px;
}
#footer-klak-desc {
    line-height: 21px;
    font-size: 14px;
    font-weight: 400;
}
#footer-large {
    display: none;
}
#footer-small {
    display: flex;
}

@media (min-width: 768px) {
    #footer-logo {
        height: 35.95px;
        width: 31.21px;
    }
    #footer-klak {
        font-size: 28px;
    }
    #footer-klak-desc {
        line-height: 30.06px;
        font-size: 18px;
        font-weight: 500;
    }
    #footer-large {
        display: flex;
    }
    #footer-small {
        display: none;
    }
}
