#manageHeadStyle {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -1%;
}
#manageDescStyle {
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 768px) {
    #manageHeadStyle {
        font-size: 40px;
        line-height: 48.41px;
        letter-spacing: -2%;
    }
    #manageDescStyle {
        font-size: 18px;
        line-height: 32px;
    }
}
