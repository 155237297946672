#iccon {
    width: 35px;
    height: 35px;
}

#tabHead {
    font-weight: 500;
    font-size: 35px;
    padding-bottom: 0.8rem;
}
.desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 30.6px;
}

/* Apply different styles for medium screens and larger using a media query */
@media (max-width: 768px) {
    .custom-style {
        padding-top: 100px;
    }
    #tabHead {
        font-weight: 600;
        font-size: 20px;
    }
    .desc {
        font-size: 16px;
        line-height: 24px;
    }

    #iccon {
        width: 50px;
        height: 50px;
    }
}
