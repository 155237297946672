#card-item {
    background-color: #f2f5fa;
}
#beta-ver {
    font-weight: 600;
    font-size: 18px;
}
#beta {
    font-size: 10px;
    background-color: #fcfeff;
    /* padding: 5px; */
    font-weight: 400;
    border-radius: 15px;
}

@media (min-width: 768px) {
    #d-card-container {
        padding-left: 130px;
        padding-right: 130px;
    }

    #card-item {
        background-color: #fafcff;
    }
    #beta-ver {
        font-weight: 500;
        font-size: 18px;
    }
    #beta {
        font-size: 16px;
        background-color: #e6ebf5;
        padding: 5px;
        border-radius: 10px;
    }
}
