#chat-font {
    width: 40px;
    height: 40px;
}
.contact-head {
    font-size: 22px;
    font-weight: 600;
    line-height: 35px;
}
#under-icon {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: '#394049';
}
#icon-desc {
    font-size: 18px;
    font-weight: 500;
}

/* Apply different styles for medium screens and larger using a media query */
@media (min-width: 768px) {
    #chat-font {
        width: 45px;
        height: 45px;
    }
    .contact-head {
        font-size: 22px;
        font-weight: 600;
        line-height: 35px;
    }
    /* .contact-head {
    } */
}
