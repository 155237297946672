.producthero {
    &--title {
        color: #111a24;
        font-weight: 600;
        font-size: 35px;
    }
    &--text {
        font-size: 18px;
        color: #60666d;
        // padding-bottom: 3rem;
    }
    &--mobile_title {
        color: #111a24;
        font-weight: 600;
        font-size: 20px;
    }
    &--mobile_text {
        font-size: 16px;
        color: #60666d;
        // padding-bottom: 3rem;
    }

    &__card {
        &--title {
            font-size: 25px;
            font-weight: 600;
            color: rgba(17, 26, 36, 1);
            cursor: pointer;
            &:hover {
                color: #60666d;
            }
        }
        &--mobile_title {
            font-size: 18px;
            font-weight: 600;
            color: rgba(17, 26, 36, 1);
            cursor: pointer;
            &:hover {
                color: #60666d;
            }
        }

        &--link {
            font-weight: 600;
            font-size: 16px;
            text-decoration: none;
            color: #2c4a8b;
            border: 1px solid #2c4a8b;
            border-radius: 7px;
            padding: 11px 30px 11px 30px;
            transition: all ease-in-out 0.3s;
            &:hover {
                border: 1px solid #2c4a8b;
                color: #fff;
                border-radius: 10px;
                background-color: #2c4a8b;
            }
        }
        &--mobile_link {
            font-weight: 600;
            font-size: 12px;
            text-decoration: none;
            color: #fff;
            border: 1px solid #2c4a8b;
            background-color: #2c4a8b;
            border-radius: 7px;
            padding: 11px 30px 11px 30px;
            transition: all ease-in-out 0.3s;
        }
    }
    &__col {
        &--text {
            font-size: 18px;
            color: #60666d;
        }
        &--moobile_text {
            font-size: 12px;
            color: #60666d;
        }
    }
    &__image {
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 5px;
        transition: all ease-in-out 0.3s;
    }
}
.productCard {
    &--title {
        font-size: 25px;
        color: rgba(17, 26, 36, 1);
        cursor: pointer;
        &:hover {
            color: #60666d;
        }
    }
}

@media screen and (max-width: (788px)) {
    .producthero {
        &--text {
            font-size: 16px;
        }
    }
}
