.img-custom {
    width: '';
    height: '';
}

/* small screen */
@media only screen and (max-width: 600px) {
    .img-custom {
        width: 350px;
        height: '';
    }
}
