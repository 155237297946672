#benefit-head {
    font-weight: 500;
    font-size: 40px;
    padding-bottom: 0.8rem;
    color: #111a24;
    /* letter-spacing: -1.8px; */
}

@media (max-width: 768px) {
    #benefit-head {
        font-weight: 600;
        font-size: 22px;
        /* line-height: 50px; */
    }
}
