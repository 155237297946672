.fa-cancel {
    color: #b9c3d8;
    font-size: 24px;
    opacity: .7;
    font-weight: 400;
}
.fa-check {
    color: $primary;
    font-size: 24px;
}

.hide-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}
.sub_text {
    color: $dark-100;
    font-size: small;
    &--colored {
        color: $primary;
        font-size: small;
        
    }
}

.plans {
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
    &--btn {
        background-color: $primary ;
        color: $white ;
        border-radius: 10px ;
        width: 185px;
        height: 50px;
        border: 1px solid $primary;
        font-weight: 500;
        font-size: 16px;
    }
    &--outline {
        border: 1px solid $primary;
        color: $primary;
        border-radius: 10px ;
        width: 185px;
        height: 50px;
        background-color: transparent;
        font-weight: 500;
        font-size: 16px;
    }
    &__toggle {
        border-radius: 40px;
        border: 1px solid $primary;
        &--btn {
            border-radius: 30px;
            height: auto;
            padding: 1rem 3rem;
            border: none;
        }
    }
    &__row {
        // height: 50vh;
        white-space: nowrap;
        .plans--col {
            flex: 0 0 auto;
            width: 100%;
            max-width: 300px; /* Adjust the max-width as needed */
            box-sizing: border-box;
            padding: 0 10px;
        }
    }
    &__card {
        border-radius: 10px !important;
        &--price {
            font-weight: 600;
            color: $dark-100;
            font-size: 25px;
            text-decoration: line-through;
        }
        &--title {
            color: $dark;
            font-weight: 600;
            font-size: 25px;
            line-height: 37.5px;
        }
        &--text {
            color: $gray;
            font-size: 16px;
        }
        &--amount {
            color: $dark;
            font-weight: 600;
            font-size: 45px;
            letter-spacing: -1.6px;
        }
        &--badge {
            color: $dark;
            padding: 11px 22px;
            background-color: $blue-200;
            border-radius: 20px;
            gap: 10px;
            font-weight: 500;
        }
        &--btn {
            border-radius: 7px;
            border: .5px solid #CFD1D3 !important;
            font-weight: 500;
            background-color: #FFFFFF;
            color: $primary;
            font-size: 12px;
            height: 32px;
            width: 98px;
        }
    }
    &__card2 {
        border-radius: 10px !important;
        background-color: #f2fafd !important;
    }
    &__categories {
        &--title {
            font-weight: 600;
            font-size: 40px;
            letter-spacing: -1.2px;
        }
        &--card {
            &-title {
                font-weight: 600;
            }
            &-text {
                margin-bottom: 2.5rem;
            }
            &-icon {
                font-weight: 600;
                color: $primary;
                font-size: x-large;
                width: 16px;
            }
        }
    }
    &__hover {
        background-color: $semi-primary !important;
        border-left: 2px solid $blue-800 !important;
        border-right: 2px solid $blue-800 !important;
        cursor: pointer;
    }
    &__table {
        min-width: 800px;
        border-collapse: separate;
        border-spacing: 0;
        th,
        td {
            &:first-child,
            &:last-child {
                border-right: 1px solid $blue-800;
            }
        }
        tfoot {
            tr {
                td {
                    padding-bottom: 2rem !important;
                    &.plans__hover {
                        border-bottom: 2px solid $blue-800 !important;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }
        td {
            border-bottom: none;
            padding: 1.5rem 0 !important;
        }
        td,
        th {
            // border-left: 1px solid #ccc;
            padding: 1.5rem 0 !important;
            width: 25%;
            align-items: center;
        }
        tr:first-child td {
            border-left: none;
        }
        tr:last-child td {
            border-bottom: 0;
        }

        th {
            &.plans__hover {
                // Apply top border to th elements
                border-top: 2px solid $blue-800 !important;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                padding-top: 2rem !important;
            }
        }
    }
}
@media screen and (max-width: 788px) {
    .plans {
        &__toggle {
            margin-top: 4rem;
            &--btn {
                height: 47px;
                padding: 10px;
            }
        }
        &__card {
            &--badge {
                color: $dark;
                padding: 8px 22px;
                margin-top: 20px;
                font-size: 14px;
                font-weight: 500;
            }
            &--amount {
                font-size: 25px;
            }
            &--price {
                font-size: 18px;
            }
        }

        &__categories {
            &--title {
                font-size: 35px;
            }
            &--card {
                &-title {
                    font-weight: 600;
                    font-size: 20px;
                }
                &-text {
                    font-size: 14px;
                }
                &-img {
                    margin: 1rem;
                }
            }
        }
        &__slider {
            &--btn {
                border-radius: 50%;
                background-color: $white;
                border: 1px solid $primary;
                color: $primary;
                font-size: 20px;
                padding: 0.5rem 1rem;
            }
            &--disabled {
                color: $blue-800 !important;
                border: 1px solid $blue-800 !important;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .plans {
        &__toggle {
            margin-top: 4rem;
            &--btn {
                height: 47px;
                padding: 10px;
            }
        }
    }
}
@media screen and (max-width: 1025px) {
    .plans {
    }
}
