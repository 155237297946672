#rowLay {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
    margin-bottom: 2rem;
}

@media (max-width: 768px) {
    #rowLay {
        flex-direction: column;
    }

    #colDiv {
        flex: none;
        width: calc(100% - 20px); /* Account for margin */
    }
}
