.verification {
    &--inp {
        width: 76px !important;
        text-align: center;
        height: 72.04px;
        font-size: 32px !important;
        font-weight: 600;
        border-radius: 10px !important;
        background-color: transparent !important;
        border: 1px solid #CFD1D3 !important;
        margin-right: 30px;
        &::placeholder {
            font-size: 14px;
            color: #b0b3b6 !important;
        }
        &:focus{
            border: 1px solid $primary !important;
        }
    }
    &--title{
        font-weight: 500;
        font-size: 40px;
        color: #111A24;
        letter-spacing: -1.2px;
        margin-bottom: 20px;

    }
    &--description{
        margin-bottom: 48px;
        color: #394049;
        font-size: 20px;
    }
    &--text{
        font-size: 16px;
        color: #394049;
        margin-bottom: 56px;
        letter-spacing: -0.16;
        font-weight: 400;
    }
    &--btn{
        letter-spacing: -0.16;
        color: $primary;
    }
    &--footText{
        font-size: 18px;
        padding-top: 36px;
        padding-bottom: 36px;
    }
}

@media screen and (width <= 768px) {
    .verification{
        // background-color: yellowgreen;
        &--inp {
            margin-right: 10px;
            width: 56px !important;
            height: 56px;
        }

    }
}