.plans {
    .table-responsive {
        overflow-x: auto;
    }
    .sub_text {
        font-weight: 500;
        font-size: 16px !important;
    }
    &__pricing {
        &--header {
            top: 10%;
            position: sticky;
            position: -webkit-sticky;
        }
    }
    &__table {
        font-family: 'Inter', sans-serif;
        &--toolbar {
            margin-top: -10px;
        }
        &--subtext {
            font-weight: 500 !important;
            font-size: 16px !important;
            color: #60666d;
        }
        &--header {
            font-size: 25px;
            color: $dark;
            font-weight: 600;
            letter-spacing: -1%;
            line-height: 37.5px;
        }
        &--subheader {
            font-weight: 600;
            font-size: 32px;
            letter-spacing: -0.32px;
            line-height: 44.8px;
            background-color: green;
            padding: 10px 0 0 0 !important;
        }

        &--features {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            padding: 5rem !important;
        }
        &--text {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
        &--menu {
            border: 1px solid #cfd1d3;
            margin: 10px;
            padding: 0.8rem 1rem !important;
            border-radius: 100px;
            gap: 4px;
            font-weight: 600;
            font-size: 16px;
            text-decoration: none;
            &:hover {
                background-color: #bbc0c556;
            }
        }
        tbody {
            display: block;
            // height: 500px;
            overflow: auto;
            // overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        thead,
        tbody tr {
            top: 0;
            display: table;
            width: 100%;
            table-layout: fixed;
        }
        thead {
            position: -webkit-sticky;
            position: sticky;
            top: 7%;
            z-index: 999;
        }
    }
    &__scroll {
        top: -8%;
        display: block;
        overflow: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__mobile {
        position: sticky;
        position: -webkit-sticky;
        top: 0%;

        border-bottom: 1px solid #ccc;
        z-index: 100;

        .scrolling-content {
            z-index: -1;
            overflow-y: auto; /* Enable vertical scrolling if the content overflows */
            max-height: 200px; /* Set a max height or adjust as needed */
            /* Add styling for the scrolling content */
        }

        .nav-link {
            color: $dark;
            font-weight: 600;
            font-size: 18px;
            padding: 1rem 1.2rem;
        }
        .nav-item {
            .active {
                border-bottom: 2px solid #2c4a8b !important;
                color: $dark !important;
            }
        }
        &--title {
            font-weight: 600;
            font-size: 25px;
            letter-spacing: -1px;
        }
        &--features {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
        &--text {
            font-weight: 500;
            font-size: 16px;
        }
    }
}
