#background-image {
    background-image: url('../../../../assets/images//new_product/moHero.png');
    background-size: cover;
    background-position: center;
    /* position: fixed; */
    /* top: 0;
    left: 0; */
    width: 100%;
    margin-top: 4rem;
    height: 50vh;
    /* z-index: -1; */
}

#retailStyle {
    font-size: 12px;
    line-height: 16.8px;
    padding-top: 77px;
}
#klakSellaStyle {
    font-size: 25px;
    line-height: 32px;
}
#heroDesc {
    font-size: 16px;
    line-height: 24px;
    width: 80%;
}

@media (min-width: 380px) {
    #background-image {
        background-image: url('../../../../assets/images//new_product/moHero.png');
        background-size: cover;
        background-position: center;
        /* position: fixed; */
        /* top: 0;
        left: 0; */
        width: 100%;
        margin-top: 4rem;
        height: 40vh;
        /* z-index: -1; */
    }
}

@media (min-width: 768px) {
    #background-image {
        background-image: url('../../../../assets/images//new_product/producthero.png');
        background-size: cover;
        background-position: center;
        /* position: fixed; */
        top: 0;
        left: 0;
        width: 100%;
        margin-top: 4rem;
        height: 100vh;
        z-index: -1;
    }

    #retailStyle {
        font-size: 18px;
        line-height: 28.8px;
        padding-top: 200px;
    }
    #klakSellaStyle {
        font-size: 50px;
        line-height: 60.51px;
    }
    #heroDesc {
        font-size: 25px;
        line-height: 30.26px;
        width: 50%;
        margin-bottom: 5rem;
    }
}
